/* General Styles for Slider Section */
.slider_section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 0 60px 0; /* Increased top padding to avoid navbar overlap */
  position: relative;
  margin-top: 80px; /* Additional margin to ensure content is below navbar */
}

/* Image Box */
.slider_section .img-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
}

/* Image Styling */
.slider_section .img-box img {
  width: 100%;
  height: auto;
  max-width: 450px; /* Increased max-width for better visibility */
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15); /* Softer shadow */
  border: none; /* Remove the border */
}

/* Button Box */
.slider_section .btn-box {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 2;
}

/* Button Styling */
.slider_section .btn-box .btn1 {
  display: inline-block;
  padding: 14px 28px;
  background-color: #f8842b;
  color: #ffffff;
  border-radius: 8px; /* Reduced border-radius for a cleaner look */
  transition: all 0.3s;
  border: none; /* Removed border */
  text-decoration: none;
  font-weight: 600; /* Slightly lighter font-weight for a modern feel */
  font-size: 18px; /* Slightly smaller font size */
  font-family: 'Roboto', sans-serif; /* Changed font for a clean look */
  letter-spacing: 0.5px;
  text-transform: uppercase;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Softer shadow */
}

/* Button Hover Effect */
.slider_section .btn-box .btn1:hover {
  background-color: #ffffff;
  color: #f8842b;
  transform: translateY(-3px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15); /* Softer hover shadow */
}

/* Carousel Indicators */
.carousel-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.carousel-indicators li {
  background-color: #ccc; /* Light gray indicators for a cleaner look */
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.carousel-indicators li.active {
  background-color: #f8842b;
  transform: scale(1.2);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .slider_section {
    padding: 40px 0;
  }

  .slider_section .img-box {
    padding-bottom: 60px;
  }

  .slider_section .img-box img {
    max-width: 100%;
    border-radius: 10px;
  }

  .slider_section .btn-box .btn1 {
    padding: 12px 24px;
    font-size: 16px;
  }

  .carousel-indicators {
    margin-top: 15px;
  }

  .carousel-indicators li {
    width: 10px;
    height: 10px;
  }

  .carousel-indicators li.active {
    transform: scale(1.1);
  }
}

@media (max-width: 576px) {
  .slider_section .btn-box .btn1 {
    padding: 10px 18px;
    font-size: 14px;
  }

  .carousel-indicators li {
    width: 8px;
    height: 8px;
  }
}
