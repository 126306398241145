.how-it-works {
    text-align: center;
    padding: 60px 20px;
    background-color: #f9f9f9;
}

.how-it-works .heading_container {
    margin-bottom: 40px;
    position: relative;
    align-items: center;
}

.how-it-works .heading_container h2 {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.how-it-works .heading_container p {
    font-size: 25px;
    color: #777;
}



.how-it-works .steps_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.how-it-works .step {
    width: 300px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
    overflow: hidden;
    margin-top: 20px;
}

.how-it-works .step:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.how-it-works .step.active {
    background-color: #f8842b;
    color: #fff;
}

.how-it-works .step.active .icon-box {
    color: #fff;
}


.how-it-works .step .icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    font-size: 50px;
    color: #f8842b;
    transition: all 0.3s ease-in-out;
}

.how-it-works .step:hover .icon-box {
    color: #ff0000;
}

.how-it-works .step:active .icon-box {
    color: #ffffff;
}
.how-it-works .step .detail-box {
    margin-top: 20px;
}

.how-it-works .step .detail-box h5 {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 10px;
    color: #333;
    transition: color 0.3s ease-in-out;
}

.how-it-works .step .detail-box p {
    font-size: 16px;
    color: #666;
}

.how-it-works .step .detail-box span {
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    border-bottom: 2px solid #fff;
    transition: color 0.3s ease-in-out, border-bottom-color 0.3s ease-in-out;
}

.how-it-works .step .detail-box span:hover {
    color: #f8842b;
    border-bottom-color: #f8842b;
}

@media (max-width: 768px) {
    .how-it-works .step {
        width: 100%;
        padding: 15px;
    }

    .how-it-works .step .icon-box {
        height: 80px;
        font-size: 40px;
    }

    .how-it-works .step .detail-box h5 {
        font-size: 18px;
    }

    .how-it-works .step .detail-box p {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .how-it-works .step {
        margin-top: 15px;
    }

    .how-it-works .step .icon-box {
        height: 60px;
        font-size: 30px;
    }

    .how-it-works .step .detail-box h5 {
        font-size: 16px;
    }

    .how-it-works .step .detail-box p {
        font-size: 12px;
    }
}
