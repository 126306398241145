.footer_container {
  background-color: #f8842b;
  color: #fff;
  padding: 40px 0;
  direction: rtl;
  position: relative;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
}

.info_section {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 30px;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info_detail h4 {
  font-size: 2rem;
  margin-bottom: 15px;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.info_detail p {
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
}

.info_contact h4 {
  font-size: 2rem;
  margin-bottom: 15px;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.contact_link_box a {
  display: flex;
  align-items: center;
  color: #fff;
  margin-bottom: 12px;
  font-size: 1.1rem;
  text-decoration: none;
  transition: all 0.3s ease;
  padding: 8px 12px;
  border-radius: 8px;
}

.contact_link_box a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateX(-5px);
}

.info_social {
  margin-top: 30px;
  width: 100%;
  max-width: 500px;
}

.social_links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.social_links a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: #fff;
  font-size: 1.5rem;
  transition: all 0.3s ease;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.social_links a:hover {
  background-color: #fff;
  color: #f8842b;
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.social_links a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255,255,255,0.2) 0%, rgba(255,255,255,0) 70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.social_links a:hover::before {
  opacity: 1;
}

.footer_section {
  padding: 20px 0;
  text-align: center;
}

.footer_section p {
  margin: 0;
  font-size: 1.1rem;
  opacity: 0.9;
}

.footer_section a {
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 2px 5px;
  border-radius: 4px;
}

.footer_section a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  text-decoration: none;
}