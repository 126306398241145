.client_section {
  padding: 40px 20px;
  background-color: #f8f9fa;
}

.heading_container {
  text-align: center;
  margin-bottom: 30px;
}

.heading_container h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
}

.carousel-inner .carousel-item {
  text-align: center;
}

.box {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto 20px;
}

.detail-box {
  text-align: center;
  color: #333;
}

.client_name {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.client_message {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.5;
}

.carousel-indicators {
  bottom: -30px;
}

.carousel-indicators li {
  background-color: #333;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.carousel-indicators .active {
  background-color: #666;
}

.facebook-reviews-link {
  text-align: center;
  margin-top: 20px;
}

.facebook-reviews-link .btn {
  background-color: #3b5998;
  color: white;
  padding: 10px 20px;
  font-size: 1.2rem;
  border-radius: 5px;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
}

.facebook-reviews-link .btn:hover {
  background-color: #2d4373;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .heading_container h2 {
    font-size: 2rem;
  }

  .box {
    padding: 15px;
  }

  .client_name {
    font-size: 1.2rem;
  }

  .client_message {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .heading_container h2 {
    font-size: 1.75rem;
  }

  .box {
    padding: 10px;
  }

  .client_name {
    font-size: 1rem;
  }

  .client_message {
    font-size: 0.9rem;
  }
}

.stars {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.stars .fa-star {
  color: #ffd700;
  font-size: 1.25rem;
}

.stars .fa-star.checked {
  color: #ffd700;
}

.stars .fa-star:not(.checked) {
  color: #e0e0e0;
}
.facebook-review-link {
  margin-top: 20px;
}

.facebook-review-link a {
  color: #fa5151; /* Facebook blue color */
  font-size: 1.5rem; /* Icon size */
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.facebook-review-link a i {
  margin-right: 8px;
}

.facebook-review-link a:hover {
  color: #fa5151; /* Slightly darker on hover */
}