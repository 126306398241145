.what_to_buy_section {
    text-align: center;
    padding: 60px 20px;
    margin-top: -30px;
    background-color: #f9f9f9;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
}

.what_to_buy_section .heading_container {
    margin-bottom: 40px;
    position: relative;
    align-items: center;
}

.what_to_buy_section .heading_container h2 {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.product_scroll_container {
    position: relative;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    overflow: hidden; /* Hide scrollbar */
}

.product_scroll {
    display: flex;
    overflow-x: auto; /* Enable horizontal scroll */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on touch devices */
    scroll-behavior: smooth; /* Smooth scroll for modern browsers */
    width: 100%; /* Full width to accommodate scrolling */
    scroll-snap-type: x mandatory; /* Snap scrolling for better experience */
}

.product_scroll::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers */
}

.product_scroll .product_item {
    flex: 0 0 auto;
    width: 150px;
    height: 150px;
    margin: 0 10px;
    background-color: #fff;
    border: 2px solid orange;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    transition: transform 0.3s, border 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    scroll-snap-align: center;
}

.product_scroll .icon {
    font-size: 2rem; 
    color: #333;
    transition: transform 0.3s;
}

.product_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.product_scroll .product_item:hover .icon {
    transform: scale(1.1);
    color: darkorange;
}

.dialog_image {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
}

/* Scroll buttons */
.scroll_button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 24px;
    cursor: pointer;
    z-index: 10;
    padding: 10px;
    border-radius: 50%;
    transition: background-color 0.3s;
}

.scroll_button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.scroll_button.left {
    left: 10px;
}

.scroll_button.right {
    right: 10px;
}

/* Media Queries */
@media (max-width: 768px) {
    .product_scroll .product_item {
        width: 120px;
        height: 120px;
    }
}

@media (max-width: 480px) {
    .product_scroll .product_item {
        width: 100px;
        height: 100px;
    }
}

@media (min-width: 769px) {
    .scroll_button {
        display: block; /* Show scroll buttons on desktop */
    }
}

@media (max-width: 768px) {
    .scroll_button {
        display: none; /* Hide scroll buttons on mobile */
    }
}
