/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Define CSS Variables for consistent theming */
:root {
  --primary-color: #f97316;
  --primary-dark: #ea580c;
  --primary-light: #fff7ed;
  --text-color: #333333;
  --background-color: #fffbf5;
  --card-background: #ffffff;
  --border-radius: 12px;
  --box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --transition: all 0.3s ease;
}

/* Base styles */
body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

/* Layout containers */
.container {
  min-height: 100vh;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: linear-gradient(135deg, #fff7ed 0%, #fff 100%);
}

.card {
  width: 100%;
  max-width: 60rem;
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  margin-top: 2rem;
}

/* Header styles */
.title {
  font-size: 2.5rem;
  font-weight: 800;
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  padding: 2rem;
  margin: 0;
}

/* Section styles */
.feedbackForm, .feedbackListContainer {
  padding: 2rem;
}

.feedbackForm {
  background: white;
  border-radius: 16px;
  padding: 2rem;
  margin: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.sectionTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primary-dark);
  margin-bottom: 1.5rem;
  text-align: center;
}

/* Feedback list styles */
.feedbackList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem;
  max-height: 600px;
  overflow-y: auto;
}

/* Scrollbar styles for the feedback list */
.feedbackList::-webkit-scrollbar {
  width: 8px;
}

.feedbackList::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.feedbackList::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
}

.feedbackList::-webkit-scrollbar-thumb:hover {
  background: var(--primary-dark);
}

/* Individual feedback item styles */
.feedbackItem {
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 1px solid #f0f0f0;
}

.feedbackItem:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.feedbackText {
  font-size: 1rem;
  line-height: 1.6;
  color: #444;
  margin-bottom: 1rem;
}

.feedbackMeta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #f0f0f0;
  padding-top: 1rem;
  margin-top: auto;
}

/* Star rating styles */
.starRating {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin: 1.5rem 0;
}

.starButton, .starFilled, .starEmpty {
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
}

.starButton {
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #ddd;
  transition: all 0.3s ease;
  padding: 0.25rem;
}

.starButton:hover, .starFilled {
  color: #ffd700;
  transform: scale(1.2);
}

.starEmpty {
  color: #d1d5db;
}

/* Animation for star fill */
.starFilled {
  animation: starFill 0.3s ease forwards;
}

@keyframes starFill {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

/* Form styles */
.textarea {
  width: 100%;
  padding: 1.2rem;
  border-radius: 12px;
  border: 2px solid #f0f0f0;
  font-size: 1rem;
  transition: all 0.3s ease;
  resize: vertical;
  min-height: 120px;
  background: #fcfcfc;
}

.textarea:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 4px rgba(249, 115, 22, 0.1);
  background: white;
}

.formFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
}

/* Button styles */
.submitButton {
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  color: white;
  padding: 1rem 2rem;
  border-radius: 12px;
  border: none;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: auto;
  min-width: 200px;
  margin: 0 auto;
}

.submitButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(249, 115, 22, 0.2);
}

.submitButton:active {
  transform: translateY(0);
}

.sendIcon {
  margin-right: 0.5rem;
}

/* User information styles */
.userName {
  font-weight: 600;
  color: var(--primary-dark);
  font-size: 0.9rem;
}

/* Loading state styles */
.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #fff7ed 0%, #fff 100%);
}

.lottieAnimation {
  width: 200px;
  height: 200px;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
}

.spinner {
  border: 4px solid rgba(249, 115, 22, 0.1);
  border-left-color: var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% { transform: rotate(360deg); }
}

/* Not logged in state styles */
.notLoggedInContainer {
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 20px;
}

.notLoggedInText {
  margin-bottom: 15px;
  color: #6c757d;
}

.loginButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
}

.loginButton:hover {
  background-color: #d16f1a;
}

.loginIcon {
  margin-right: 10px;
}

/* Feedback stats styles */
.feedbackStats {
  background: white;
  border-radius: 16px;
  padding: 1.5rem;
  margin: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.feedbackCount {
  font-weight: 600;
  color: var(--primary-dark);
}

.averageRating {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--primary-color);
}

.averageRating .starFilled {
  font-size: 1.2rem;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .container {
    padding: 0.5rem;
  }

  .card {
    margin-top: 0.5rem;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  }

  .title {
    font-size: 1.75rem;
    padding: 1.25rem;
  }

  .feedbackForm, .feedbackListContainer {
    padding: 1rem;
  }

  .feedbackItem {
    padding: 1rem;
  }

  .feedbackStats {
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    text-align: center;
  }

  .socialLink {
    margin: 1rem 0.5rem;
    padding: 1.5rem 0;
  }

  .instagramButton {
    padding: 0.75rem 1.25rem;
    font-size: 0.9rem;
    width: 90%;
    justify-content: center;
  }

  .feedbackMeta {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }

  .starRating {
    margin: 1rem 0;
  }

  .textarea {
    min-height: 100px;
    font-size: 0.9rem;
  }

  .homeRedirect {
    top: 0.5rem;
    left: 0.5rem;
  }

  .homeButton {
    padding: 0.5rem;
  }

  .container {
    padding: 1rem;
  }

  .card {
    margin-top: 1rem;
    border-radius: 16px;
  }

  .title {
    font-size: 2rem;
    padding: 1.5rem;
  }

  .feedbackForm, .feedbackListContainer {
    padding: 1.5rem;
  }

  .feedbackItem {
    flex: 0 0 100%;
  }

  .feedbackList {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 0.25rem;
  }

  .card {
    margin-top: 0.25rem;
    border-radius: 8px;
  }

  .title {
    font-size: 1.5rem;
    padding: 1rem;
  }

  .sectionTitle {
    font-size: 1.25rem;
  }

  .formFooter {
    flex-direction: column;
    gap: 1rem;
  }

  .submitButton {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
    min-width: unset;
  }

  .feedbackStats {
    padding: 1rem;
  }

  .feedbackCount {
    font-size: 0.9rem;
  }

  .averageRating {
    font-size: 1rem;
  }

  .feedbackText {
    font-size: 0.9rem;
  }

  .userName {
    font-size: 0.8rem;
  }

  .starButton, .starFilled, .starEmpty {
    font-size: 1.25rem;
  }

  .instagramButton {
    width: 95%;
    padding: 0.75rem 1rem;
    font-size: 0.85rem;
  }

  .instagramButton i {
    font-size: 1.25rem;
  }

  .formFooter {
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
  }

  .starRating {
    justify-content: center;
  }

  .submitButton {
    width: 100%;
    justify-content: center;
  }
}

/* Add smooth transitions for responsive changes */
.card, .feedbackItem, .submitButton, .instagramButton {
  transition: all 0.3s ease-in-out;
}

/* Improve touch targets on mobile */
@media (hover: none) {
  .starButton, .submitButton, .instagramButton, .homeButton {
    cursor: default;
    -webkit-tap-highlight-color: transparent;
  }
  
  .starButton {
    padding: 0.5rem;
  }
  
  .homeButton, .submitButton, .instagramButton {
    padding: 0.75rem 1.25rem;
  }
}

.homeRedirect {
  position: fixed;
  top: 1rem;
  left: 1rem;
  background-color: transparent;
  z-index: 1000; /* Ensure it's on top of other content */
}

.homeButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 50%; /* Circular button */
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Floating effect */
}

.homeButton:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

.homeButton:active {
  transform: translateY(0);
}

.homeIcon {
  margin-right: 0; /* No need for margin as it's just the icon */
}

.socialLink {
  text-align: center;
  padding: 2.5rem 0;
  margin: 2rem 1rem;
  background: linear-gradient(135deg, rgba(255,255,255,0.9), rgba(255,255,255,0.95));
  border-radius: 20px;
  backdrop-filter: blur(10px);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.socialLink::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(249, 115, 22, 0.2), transparent);
}

.instagramButton {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2rem;
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  color: white;
  text-decoration: none;
  border-radius: 16px;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.4s ease;
  box-shadow: 0 4px 15px rgba(220, 39, 67, 0.2);
  position: relative;
  overflow: hidden;
}

.instagramButton::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, rgba(255,255,255,0.1), rgba(255,255,255,0));
  transition: transform 0.6s ease;
  transform: translateX(-100%);
}

.instagramButton:hover {
  transform: translateY(-3px) scale(1.02);
  box-shadow: 0 8px 25px rgba(220, 39, 67, 0.3);
}

.instagramButton:hover::before {
  transform: translateX(100%);
}

.instagramButton i {
  font-size: 1.5rem;
  background: linear-gradient(45deg, #fff, #f8f9fa);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (max-width: 768px) {
  .socialLink {
    margin: 1.5rem 1rem;
    padding: 2rem 0;
  }
  
  .instagramButton {
    padding: 0.875rem 1.5rem;
    font-size: 1rem;
  }
}

