.client_section {
    padding: 40px 20px; /* Added horizontal padding for smaller screens */
    background-color: #f8f9fa;
  }
.faq-container {
    max-width: 900px;
    margin: 20px auto;
    padding: 10px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .faq-title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 30px;
    color: #ff7f00; /* Orange color */
    font-family: 'Roboto', sans-serif;
  }
  
  .faq-list {
    list-style-type: none;
    padding: 0;
  }
  
  .faq-item {
    margin-bottom: 20px;
    background-color: #fff3e0; /* Light orange background */
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .faq-item.active {
    transform: translateY(-5px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  }
  
  .faq-question {
    cursor: pointer;
    padding: 15px 20px;
    background-color: #ff7f00; /* Orange color */
    color: white;
    font-size: 20px;
    font-weight: bold;
    border-radius: 10px 10px 0 0;
    font-family: 'Roboto', sans-serif;
  }
  
  .faq-question:hover {
    background-color: #e67300;
  }
  
  .faq-answer {
    padding: 15px 20px;
    background-color: white;
    border-radius: 0 0 10px 10px;
    font-size: 18px;
    color: #333;
    font-family: 'Roboto', sans-serif;
  }
  