/* Main Section Style */
.service_section {
    text-align: center;
    padding: 60px 20px; /* Adjust padding as needed */
    margin-top: -30px; /* Reduce space from the previous section */
    background-color: #f9f9f9; /* Light background color for contrast */
    position: relative;
    overflow: hidden; /* Ensure no overflow beyond the section */
}

/* Heading Container */
.service_section .heading_container {
    margin-bottom: 40px;
    position: relative;
    align-items: center;
}

.service_section .heading_container h2 {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.service_section .heading_container p {
    font-weight: bold;
    font-size: 25px;
    color: #555;
}

/* Box Style */
.service_section .box {
    margin-top: 25px;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    position: relative;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    background-color: #fff;
    transform-style: preserve-3d; /* Enable 3D transformations */
}

/* Decorative Elements */
.service_section .box::before {
    content: "";
    width: 300px;
    height: 300px;
    position: absolute;
    right: -150px;
    bottom: -150px;
    background-color: #faf8fd;
    z-index: -1;
    border-radius: 50%;
    transition: all 0.3s;
}

/* Image Box */
.service_section .box .img-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    overflow: hidden; /* Ensure no overflow beyond the container */
}

.service_section .box .img-box img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Fit image inside the box */
    transition: transform 0.3s, filter 0.3s;
    border: none; 
}

/* Detail Box */
.service_section .box .detail-box {
    margin-top: 20px;
    position: relative; /* Ensure text is properly aligned with pseudo-elements */
}

/* Text Styling */
.service_section .box .detail-box h5,
.service_section .box .detail-box p {
    z-index: 1; /* Ensure text appears above the pseudo-elements */
    transition: color 0.3s;
}

.service_section .box .detail-box h5 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
}

.service_section .box .detail-box p {
    font-size: 16px;
    color: #666;
    line-height: 1.4;
}

.service_section .box .detail-box a {
    color: #f8842b;
    font-weight: bold;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s, transform 0.3s;
}

.service_section .box .detail-box a span {
    margin-right: 10px;
}

/* Hover Effects */
.service_section .box:hover {
    transform: translateY(-10px) rotateX(5deg) rotateY(5deg); /* Subtle 3D rotation */
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.service_section .box:hover::before {
    transform: scale(4); /* Smaller scale to avoid covering content */
    background-color: #f8842b;
}

.service_section .box:hover .img-box img {
    transform: scale(1.1); /* Slightly enlarge image */
    filter: brightness(0.8); /* Darken image on hover */
}

.service_section .box .detail-box a:hover {
    color: #ffffff;
    transform: translateX(5px); /* Slide effect on hover */
}
.service_section .box .detail-box p:hover {
    font-size: 18px;
    color: #ffffff;
    line-height: 1.4;
}


/* Button Box */
.service_section .btn-box {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.service_section .btn-box a {
    display: inline-block;
    padding: 12px 50px;
    background-color: #f8842b;
    color: #ffffff;
    border-radius: 8px;
    transition: all 0.3s;
    border: 1px solid #f8842b; /* Border color matching background */
    font-size: 18px;
    text-transform: uppercase; /* Uppercase text */
}

.service_section .btn-box a:hover {
    background-color: #d6731b; /* Slightly darker background on hover */
    border-color: #d6731b; /* Match border with background on hover */
    color: #ffffff;
    transform: translateY(-3px); /* Lift button effect */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .service_section .box {
        padding: 15px;
    }

    .service_section .box .img-box {
        height: 100px;
    }

    .service_section .box .img-box img {
        width: 80px;
    }

    .service_section .box .detail-box h5 {
        font-size: 18px;
    }

    .service_section .box .detail-box p {
        font-size: 14px;
    }

    .service_section .btn-box a {
        padding: 10px 40px;
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .service_section .box {
        margin-top: 15px;
    }

    .service_section .box .img-box {
        height: 80px;
    }

    .service_section .box .img-box img {
        width: 120px;
    }

    .service_section .box .detail-box h5 {
        font-size: 16px;
    }

    .service_section .box .detail-box p {
        font-size: 12px;
    }

    .service_section .btn-box a {
        padding: 8px 30px;
        font-size: 14px;
    }
}
