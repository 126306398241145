/* Floating Announcement Section */
.floating-announcement-section {
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust the position to exactly center */
  width: 300px;
  background-color: rgba(255, 255, 255, 0.9); /* Slight transparency for modern look */
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensures it's above other content */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
}

/* Close Button for Announcement Section */
.close-announcement-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
}

/* Lottie Animation Styling */
.lottie-animation {
  width: 150px;
  height: 150px;
  margin-bottom: 10px;
}

/* Swiper Content Styling */
.announcement-swiper {
  width: 100%;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.announcement-alert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border-left: 4px solid #ff6600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.announcement-alert:hover {
  background-color: #fffae6;
}

.announcement-text {
  font-size: 1rem;
  color: #333;
  margin: 0;
  flex-grow: 1;
}

.announcement-icon {
  color: #ff6600;
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .floating-announcement-section {
    width: 90%; /* Take most of the screen width on mobile */
    transform: translate(-50%, -50%); /* Center horizontally and vertically */
  }

  .lottie-animation {
    width: 100px; /* Smaller Lottie for mobile */
    height: 100px;
  }
}
