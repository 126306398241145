.newsletter_signup_section {
    text-align: center;
    padding: 60px 20px;
    margin-top: -30px;
    background-color: #f9f9f9;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    
}

.newsletter_signup_section .heading_container {
    margin-bottom: 40px;
    position: relative;
    align-items: center;
}

.newsletter_signup_section .heading_container h2 {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.signup_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.email_input {
    width: 100%;
    max-width: 400px;
    padding: 15px;
    border: 2px solid orange;
    border-radius: 20px;
    outline: none;
    font-size: 1rem;
    text-align: right; /* Align text to the right for Arabic */
    transition: border-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.email_input:focus {
    border-color: darkorange;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.signup_button {
    padding: 15px 30px;
    background-color: orange;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 10px; /* Add margin for better spacing */
}

.signup_button:hover {
    background-color: darkorange;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feedback_message {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border-radius: 20px;
    display: inline-block;
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s, transform 0.5s;
}

.feedback_message.show {
    opacity: 1;
    transform: translateY(0);
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
    .email_input {
        max-width: 300px;
        padding: 12px;
        font-size: 0.9rem;
    }

    .signup_button {
        padding: 12px 25px;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .email_input {
        max-width: 250px;
        padding: 10px;
        font-size: 0.8rem;
    }

    .signup_button {
        padding: 10px 20px;
        font-size: 0.8rem;
    }
}
.spinner-border {
    border: 0.2em solid rgba(0, 0, 0, 0.1); /* Light gray border */
    border-left: 0.2em solid #000; /* Black spinner */
    border-radius: 50%;
    width: 1em;
    height: 1em;
    animation: spin 0.75s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  